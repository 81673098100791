import React from 'react'
import ChatGPT from '../../../templates/chatgpt'

const ChatGPTEsPage = () => {
    return (
        <ChatGPT lang="es">
        </ChatGPT>
    )
}

export default ChatGPTEsPage
